:root {
 --main-bg-color: #EB6E35;
 --main-text-color: #EB6E35;
 --second-text-color: #343a40;
 --second-bg-color: #7380ec;
} 
/********/
.card-blue,.card-green,.card-other,.card-red{
    box-shadow: 1px 1px 8px #000 !important;
}
.card-blue{
  background-color:#01579b !important;
}
.card-red{
  background-color: #dd2c00 !important;
}
.card-green{
  background-color: #4db6ac !important;
}
.card-other{
  background-color: #d81b60 !important;
}
/********/

.white-text:hover{
  color:#FFF;
  opacity: 0.4;
}

.primary-text {
 color: var(--main-text-color);
}
.white-text{
color:#FFF
}
.second-text {
 color: var(--second-text-color);
}

.success-text{
  color:#4db6ac
}
.danger-text{
  color:#dd2c00;
}
.warning-text{
  color:#fdd835
}
.primary-bg {
 background-color: var(--main-bg-color);
}

.secondary-bg {
 background-color: var(--second-bg-color);
}

.rounded-full {
 border-radius: 100%;
}

#wrapper {
 overflow-x: hidden;
 background-image: linear-gradient(
   to right,
   #7380ec,
   #7d88ec,
   #8d97ed,
   #9ba3ea,
   #aab1ec
 );
}

#sidebar-wrapper {
 min-height: 100vh;
 margin-left: -15rem;
 -webkit-transition: margin 0.25s ease-out;
 -moz-transition: margin 0.25s ease-out;
 -o-transition: margin 0.25s ease-out;
 transition: margin 0.25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
 padding: 0.875rem 1.25rem;
 font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
 width: 15rem;
}

#page-content-wrapper {
 min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
 margin-left: 0;
}

#menu-toggle {
 cursor: pointer;
}

.list-group-item {
 border: none;
 padding: 20px 30px;
}

.list-group-item.active {
 background-color: #e06a3c !important;
 color: #FFF;
 font-weight: bold;
 border: none;
}
.disable{
 display: block;
}

.crud-btn{
  margin-right:10px
}

a{
  text-decoration: none;
}

@media (min-width: 768px) {
 #sidebar-wrapper {
   margin-left: 0;
 }

 #page-content-wrapper {
   min-width: 0;
   width: 100%;
 }

 #wrapper.toggled #sidebar-wrapper {
   margin-left: -15rem;
 }
 .disable{
  display: none;
 }
}