@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

body{
  font-family: "poppins";
  font-size: 14px;
}

option{
  font-family: "poppins",sans-serif;
}

a{
  cursor: pointer;
}

button:disabled{
 opacity: 0.35;
}

.label-required::after{
 content: "*";
 color:red;
}

/**********************/
.green-status,.bleu-status,.yellow-status,.red-status,.other-status{
 font-weight:bold;
 color:#FFF;
 outline: none;
 border: 0;
 border-radius: 6px;
}
.green-status{
  background-color:#44e4a0;
}
.red-status{
 background-color:#d9374e;
}
.bleu-status{
  background-color: #029bff;
}
.yellow-status{
  background-color:#fe8300;
}
.other-status{
  background-color: #d81b60;
}
/**********************/

.green-btn,.red-btn,.bleu-btn{
  font-weight:bold;
  color:#FFF;
  outline: none;
  border: 0;
  border-radius: 6px;
  padding: 8px;
  font-size: 15px;
 }

.green-btn{
  background-color:#44e4a0;
}
.red-btn{
 background-color:#d9374e;
}
.bleu-btn{
  background-color: #029bff;
}

.bleu-btn:hover{
  text-decoration: none;
  list-style: none; 
  color:#FFF
}

.red-btn:hover{
  text-decoration: none;
  list-style: none;
  color:#FFF
}
.green-btn:hover{
  text-decoration: none;
  list-style: none;
  color:#FFF
}